import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import colors from "../../../../assets/theme/base/colors";

const steps = ["Selecione un Archivo", "Selecione los Parametros", "Comprobar datos"];

/* eslint-disable */
export default function HorizontalLabelPositionBelowStepper(props) {
  const [activeStep, setActiveStep] = useState(0);

  function handleNext() {
    if(activeStep == steps.length-1)
      props.finishCallBack();
    else{
      let a = activeStep < (steps.length - 1) ? activeStep + 1 : activeStep;
      setActiveStep(a);
    }
  }

  function handleBack() {
    let a = activeStep > 0 ? activeStep - 1 : activeStep;
    setActiveStep(a);
  }

  function renderStep() {
    return React.Children.toArray(props.children).find((c) => c.props.step == activeStep);
  }

  function stepDisabled() {
    return renderStep().props.stepDisabled;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep } alternativeLabel style={{ backgroundColor: "white" }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel color={colors.grey}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <MDBox pt={6} pb={3} >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {renderStep()}
          </Grid>
        </Grid>
      </MDBox>
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }}/>
          <Button onClick={handleNext} disabled={stepDisabled()}>
            {activeStep === steps.length - 1 ? "Finalizar" : "Próximo"}
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}
