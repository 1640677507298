/* eslint-disable */
import { useState, useEffect } from "react";
// react-router-dom components
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

const backendURL = process.env.REACT_APP_NICARAGUA_BACKEND;

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [password, setPassword] = useState(null);
  const handleSetPassword = (evnt) => setPassword(evnt.target.value);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const handleSetPhoneNumber = (evnt) => setPhoneNumber(evnt.target.value);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [token, setToken] = useState(null);
  const [credentials, setCredentials] = useState(null);

  const getCredentials = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${backendURL}/user`, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw Error();
        return response.text();
      })
      .then((data) => {
        setCredentials(data);
      })
      .catch(() => openErrorSB());
  };

  const login = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({ phoneNumber, password });
    const requestOptions2 = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${backendURL}/user/login`, requestOptions2)
      .then((response) => {
        if (response.status !== 200) throw Error();
        return response.text();
      })
      .then((data) => {
        localStorage.setItem("token", data);
        setToken(data);
      })
      .catch(() => openErrorSB());
  };

  useEffect(() => {
    if (token != null) getCredentials();
  });

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Access Denied"
      dateTime={new Date().toDateString()}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  if (credentials != null) return <Navigate to="/dashboard" />;

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Log in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="tel"
                label="Número de Telephono"
                title="PhoneNumber"
                pattern="[0-9]*"
                onChange={handleSetPhoneNumber}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Contraseña" onChange={handleSetPassword} fullWidth />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={login}>
                log in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
      {renderErrorSB}
    </BasicLayout>
  );
}

export default Basic;
