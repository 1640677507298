/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

const backendUrl = process.env.REACT_APP_NICARAGUA_BACKEND;

function OrdersOverview() {
  const [alerts, setAlerts] = React.useState([]);
  const getRegions = () => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    fetch(`${backendUrl}/waterAlert?limit=10`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setAlerts(JSON.parse(result));
      })
      .catch(() => {})
      .finally(() => {});
  };

  function truncateString(string, size) {
    return string.length > size ? `${string.slice(0, size)}...` : string;
  }

  function renderAlerts() {
    return alerts.map((a, index, arr) => (
      <TimelineItem
        color={a?.priority === "High" ? "error" : "success"}
        icon={a?.priority === "High" ? "warning" : "notifications"}
        title={truncateString(a.message, 15)}
        dateTime={new Date(a.dateTime).toDateString()}
        lastItem={index + 1 === arr.length}
      />
    ));
  }
  React.useEffect(() => {
    getRegions();
  }, []);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Water Anuncios
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            Ultimos {alerts.length} anuncios.
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>{renderAlerts()}</MDBox>
    </Card>
  );
}

export default OrdersOverview;
