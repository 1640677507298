/* eslint-disable */
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
export default function WeatherLogsGrid(props) {
  props.weatherLogs.forEach((element, index) => {
    element["id"] = index;
  });
  const columns = props.weatherParameters
    .filter((e) => e.checked)
    .map((e) => ({ field: e.parameter, width: 100 }));

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <div style={{ height: 400,width:"100%"}}>
          <DataGrid
            rows={props.weatherLogs}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[15]}
          />
        </div>
      </MDBox>
    </Card>
  );
}
