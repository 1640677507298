import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import * as React from "react";
import Card from "@mui/material/Card";
import PageLayout from "examples/LayoutContainers/PageLayout";

export default function Privacy() {
  const subtitle = `This Privacy Policy governs the manner in which NicaAgua collects, uses, maintains, and discloses information collected from users (each, a "User") of the NicaAgua mobile application ("App").`;
  const content = [
    `We collect personal identification information from Users when they register on our App through our registration page. This information includes the user's name, phone number, and community name. This information is necessary for providing personalized services, facilitating communication, and enhancing the user experience within the App.`,
    `We have implemented analytics tools in our App to collect information on how Users interact with the App and which functionalities they use. This information may include user interactions, navigation paths, and other usage patterns. The analytics data is collected anonymously and is used for analytical purposes, such as understanding user behavior, improving the App's performance, and enhancing user experience.`,
    `Personal identification information: We use the personal information collected during registration to provide personalized services, communicate with Users, and facilitate community engagement within the App. \nAnalytics data: We use the analytics data to analyze trends, monitor App performance, and make improvements to the App's features and functionalities.`,
    `We adopt appropriate data collection, storage, and processing practices, as well as security measures, to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our App.`,
    `We do not sell, trade, or rent Users' personal identification information to third parties. However, we may share aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.`,
    `Frugal Innovation Hub has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.`,
    `By using this App, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our App. Your continued use of the App following the posting of changes to this policy will be deemed your acceptance of those changes.`,
    `If you have any questions about this Privacy Policy, the practices of this App, or your dealings with this App, please contact us at fih@scu.edu.`,
  ];

  const heading = [
    "Personal Identification Information",
    "Analytics",
    "How We Use Collected Information",
    "How We Protect Your Information",
    "Sharing Your Personal Information",
    "Changes to This Privacy Policy",
    "Your Acceptance of These Terms",
    "Contact Us",
  ];

  return (
    <PageLayout>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h5" gutterBottom>
            Privacy Policy
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pl={3} pr={3} pb={3}>
        <MDTypography variant="h6" gutterBottom>
          {subtitle}
        </MDTypography>
      </MDBox>
      {heading.map((h, index) => (
        <Card style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }} key={h}>
          <MDBox alignItems="center" p={3}>
            <MDTypography variant="h5" gutterBottom>
              {heading[index]}
            </MDTypography>
            <MDTypography variant="p" gutterBottom>
              {content[index]}
            </MDTypography>
          </MDBox>
        </Card>
      ))}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pl={3} pr={3} pb={3}>
        <MDTypography variant="h6" gutterBottom>
          This document was last updated on April 24, 2024.
        </MDTypography>
      </MDBox>
    </PageLayout>
  );
}
