/* eslint-disable*/
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MDTypography from "@mui/material/Typography";
import MDBox from "components/MDBox";

function ParametersSelect(props) {
  function handleCheckbox(event, isChecked) {
    var index = props.weatherParameters.findIndex((e) => e.parameter == event.target.name);
    props.weatherParameters[index].checked = isChecked;
    props.setWeatherParameters(props.weatherParameters);
  }

  function listOfParameters() {
    var teste = props.weatherParameters.map((e, idx) => {
      return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={e.checked} name={e.parameter} onChange={handleCheckbox} />
            }
            label={e.parameter}
          />
        </Grid>
      );
    });
    return teste;
  }
  return (
    <MDBox>
      <Card>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <FormGroup>
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
            Seleccione los parámetros a importar.
            </MDTypography>
          </MDBox>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {listOfParameters()}
            </Grid>
          </FormGroup>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default ParametersSelect;
