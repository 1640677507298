import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import MDSnackbar from "components/MDSnackbar";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import RegionSelect from "./RegionSelect";

const backendUrl = process.env.REACT_APP_NICARAGUA_BACKEND;
function WaterAlert() {
  const [selectOptions, setSelectOptions] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [errorSB, setErrorSB] = React.useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [successSB, setSuccessSB] = React.useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const [priority, setPriority] = React.useState("Low");

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSelectChange = (value) => {
    setRegions(value.map((e) => e));
  };

  const selectAll = () => {
    setRegions(selectOptions);
  };

  const getRegions = () => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    fetch(`${backendUrl}/region`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setSelectOptions(JSON.parse(result));
      })
      .catch(() => {
        openErrorSB();
      })
      .finally(() => {});
  };

  const putAlert = () => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        message,
        priority,
        regions: regions.map((a) => a.name.replace(" ", "_")),
      }),
      redirect: "follow",
    };
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    fetch(`${backendUrl}/waterAlert`, requestOptions)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) openSuccessSB();
        else {
          openErrorSB();
        }
      })
      .catch(() => openErrorSB())
      .finally(() => {});
  };

  React.useEffect(() => {
    getRegions();
  }, []); // <-- empty dependency array

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box sx={{ width: "100%", p: 3 }}>
          <Stack spacing={2}>
            <Button onClick={selectAll}>Selecionar Todas las Comunidades</Button>
            <MDBox>
              <Grid container spacing={3}>
                {selectOptions ? (
                  <RegionSelect
                    options={selectOptions}
                    onChange={handleSelectChange}
                    state={regions.map((a) => a)}
                  />
                ) : null}
                <Grid item xl={4}>
                  <ToggleButtonGroup
                    color="info"
                    value={priority}
                    exclusive
                    // eslint-disable-next-line no-undef
                    onChange={(e) => setPriority(e.target.value)}
                    aria-label="Platform"
                  >
                    <ToggleButton value="High">Alta Prioridad</ToggleButton>
                    <ToggleButton value="Low">Baja Prioridad</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </MDBox>
            <TextField
              id="outlined-basic"
              label="Alert Message"
              variant="outlined"
              multiline
              onChange={handleMessageChange}
              rows={4}
            />
            <div align="end">
              <Button
                endIcon={<SendIcon />}
                onClick={putAlert}
                disabled={regions.length === 0 || !message}
              >
                Send
              </Button>
            </div>
          </Stack>
        </Box>
      </Card>

      <MDSnackbar
        color="error"
        icon="warning"
        title="Error"
        content="Access Denied"
        dateTime={new Date().toDateString()}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />

      <MDSnackbar
        color="success"
        icon="check_circle_outline"
        title="Success"
        content="Alert Created"
        dateTime={new Date().toDateString()}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    </DashboardLayout>
  );
}

export default WaterAlert;
