import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
/* eslint-disable */

export default function ConfirmImportDialog(props) {
  var title,
    message = "JOW";
  var showCloseButton,
    showAcceptButton = true;
  var showLoading = false;


  function defineType(type) {
    switch (type) {
      case "Confirm":
        title = "Estas seguro?";
        message = "Desea importar estos datos, esta acción no se puede deshacer.";
        showCloseButton = true;
        showAcceptButton = true;
        break;
      case "Importing":
        title = "Aguarde!";
        message = null;
        showCloseButton = false;
        showAcceptButton = false;
        showLoading = true;
        break;
      case "Success":
        title = "Sucesso";
        message = "Los datos han sido importados.";
        showCloseButton = true;
        showAcceptButton = false;
        showLoading = false;
        break;
      case "Fail":
        title = "Error";
        message = "Algo salió mal. Vuelva a intentarlo en unos minutos o póngase en contacto con el administrador.";
        showCloseButton = true;
        showAcceptButton = false;
        showLoading = false;
        break;
      default:
        break;
    }
  }

  defineType(props.type);
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        {showLoading ? (
          <Box sx={{ display: "flex" }} alignItems="center" justifyContent="center">
            <CircularProgress color="inherit" />
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        {showCloseButton ? <Button onClick={props.Close}>Cerrar </Button> : ""}
        {showAcceptButton ? (
          <Button onClick={props.Confirm} autoFocus>
            OK
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
}
