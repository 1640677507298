/* eslint-disable*/
import Papa from "papaparse";
const xlsx = require("xlsx");

import { DropzoneArea } from "material-ui-dropzone";

function ImportExcell(props) {
  const onDrop = (e) => {
    const reader = new FileReader();
    reader.onload = () => handleExcellInput(reader.result);
    reader.readAsBinaryString(e[0]);
  };

  const readUploadFile = (e) => {
    // e.preventDefault();
    if (e) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        var json = xlsx.utils.sheet_to_json(worksheet);
        var range1 = xlsx.utils.decode_range(worksheet["!ref"]);
        range1.s.r = 10;
        var json2 = xlsx.utils.sheet_to_json(worksheet, { range: range1 });
        props.setWeatherLogs(json2);
        props.setWeatherParameters(Object.keys(json2[0]).map((a) => ({parameter:a,checked:true})));
      };
      reader.readAsArrayBuffer(e[0]);
    }
  };

  function getHeaderLine(data) {
    var i = 0;
    while (data[i][Object.keys(data[i])[0]] != "Date & Time") i++;
    return i;
  }

  function setParameters(tableHeader = {}) {
    var parameters = Object.keys(tableHeader).map((e) => ({
      parameter: tableHeader[e],
      checked: true,
    }));
    props.setWeatherParameters(parameters);
  }

  function handleExcellInput(csv) {
    var array = csv.split("\n");
    array.splice(0, 5);
    csv = array.join("\n");
    var wl = Papa.parse(csv, { header: true, newline: "\n" });
    var parameters = Object.keys(wl.data[0] ?? {}).map((e) => {
      return { parameter: e, checked: true };
    });
    props.setWeatherLogs(wl);
    props.setWeatherParameters(parameters);
  }

  return (
    <DropzoneArea
      acceptedFiles={[".csv", ".xls", ".xlsx"]}
      dropzoneText={"Arrastre y suelte un archivo de Excel."}
      filesLimit={1}
      showFileNames={true}
      showAlerts={["error"]}
      onDrop={readUploadFile}
    />
  );
}

export default ImportExcell;
