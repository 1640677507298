/* eslint-disable */
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

export default function RegionSelect(props) {

  return (
    <Grid item xl={8}>
      <Autocomplete
        onChange={(event, value) => props.onChange(value)}
        multiple
        id="tags-standard"
        options={props.options}
        getOptionLabel={(option) => option.name}
        value = {props.state}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Chose Regions" placeholder="Regions" />
        )}
      />
    </Grid>
  );
}
