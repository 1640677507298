import * as React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import Icon from "@mui/material/Icon";
import { Button, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Delete } from "@mui/icons-material";
// import Item from "@mui/material/ListItem";
const backendUrl = process.env.REACT_APP_NICARAGUA_BACKEND;

export default function Users() {
  const [users, setUsers] = React.useState([]);

  const getUsers = () => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    fetch(`${backendUrl}/users`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setUsers(JSON.parse(result));
      })
      .finally(() => {});
  };
  const setRole = (phoneNumber, roleLevel) => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({ phoneNumber, roleLevel }),
    };
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    fetch(`${backendUrl}/user/role`, requestOptions).finally(() => {});
  };

  const deleteUser = (phoneNumber) => {
    const myHeaders = new Headers();
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({ phoneNumber }),
    };
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    fetch(`${backendUrl}/user`, requestOptions)
      .then(getUsers)
      .finally(() => {});
  };

  const columns = [
    {
      field: "firstName",
      headerName: "Nombre",
      maxwidth: "flex",
      flex: 0.3,
    },
    {
      field: "lastName",
      headerName: "Apelido",
      maxwidth: "flex",
      flex: 0.3,
    },
    {
      field: "phoneNumber",
      headerName: "Telefono",
      maxwidth: "flex",
      flex: 0.3,
    },
    {
      field: "roleLevel",
      headerName: "Admin",
      maxwidth: "flex",
      flex: 0.3,
      renderCell: (params) => (
        <Switch
          checked={params.row.roleLevel === 0}
          onChange={() => {
            const user = users.find((u) => u.phoneNumber === params.row.phoneNumber);
            user.roleLevel = user.roleLevel === 0 ? 1 : 0;
            setRole(user.phoneNumber, user.roleLevel);
          }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      description: "Actions",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            deleteUser(params.row.phoneNumber);
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  React.useEffect(() => {
    getUsers();
  }, []); // <-- empty dependency array

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
              Regions
            </MDTypography>
          </MDBox>
        </MDBox> */}
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={users}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              getRowId={(row) => row.phoneNumber}
            />
          </div>
        </MDBox>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Region Info</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" alignContent="center">
            <MDBox p={1}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Code"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Country"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField id="outlined-basic" label="Latitude" variant="outlined" fullWidth />
                </Grid>
                <Grid item md={6}>
                  <TextField id="outlined-basic" label="Longitude" variant="outlined" fullWidth />
                </Grid>
              </Grid>
            </MDBox>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
