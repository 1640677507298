import * as React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import HorizontalLabelPositionBelowStepper from "./components/Stepper";
import WeatherLogsGrid from "./components/WeatherLogsGrid";
import ImportExcell from "./components/ImportExcell";
import ParametersSelect from "./components/ParametersSelect";
import ConfirmImportDialog from "./components/ConfirmDialog";

const backendUrl = process.env.REACT_APP_NICARAGUA_BACKEND;
function Tables() {
  const [weatherLogs, setWeatherLogs] = React.useState([]);
  const [weatherParameters, setWeatherParameters] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [dialogType, setDialogType] = React.useState("Confirm");

  const handleClickOpen = () => {
    setDialogType("Confirm");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const putWeather = (weatherLogsInsert) => {
    const raw = JSON.stringify(weatherLogsInsert);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${backendUrl}/weather`, requestOptions)
      .then((response) => response.text())
      .then(() => setDialogType("Success"))
      .catch(() => setDialogType("Fail"))
      .finally(() => {});
  };

  const importWeatherLogs = () => {
    const dataImport = weatherLogs.map((d) => {
      const copy = { ...d };
      const dateTime = new Date(d["Date & Time"]).toJSON();
      delete copy["Date & Time"];
      const parameters = Object.entries(copy).map((e) => {
        const arr = e[0].split(" - ");
        return { name: arr[0], unit: arr[1] ?? "-", value: e[1] };
      });
      return { dateTime, parameters, userId: "00000", regions: ["*****"] };
    });
    setDialogType("Importing");
    putWeather(dataImport);
  };

  return (
    <DashboardLayout>
      <ConfirmImportDialog
        open={open}
        type={dialogType}
        Confirm={importWeatherLogs}
        Close={handleClose}
      />
      <DashboardNavbar />
      <HorizontalLabelPositionBelowStepper finishCallBack={handleClickOpen}>
        <ImportExcell
          step={0}
          setWeatherLogs={setWeatherLogs}
          setWeatherParameters={setWeatherParameters}
          stepDisabled={weatherParameters.length === 0}
        />
        <ParametersSelect
          step={1}
          weatherParameters={weatherParameters}
          setWeatherParameters={setWeatherParameters}
        />
        <WeatherLogsGrid step={2} weatherLogs={weatherLogs} weatherParameters={weatherParameters} />
      </HorizontalLabelPositionBelowStepper>
    </DashboardLayout>
  );
}

export default Tables;
