import Dashboard from "layouts/dashboard";
import Tables from "layouts/importWeather";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Regions from "layouts/regions";
import Users from "layouts/users";
// @mui icons
import Icon from "@mui/material/Icon";
import WaterAlert from "layouts/waterAlert";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Importar Datos",
    key: "Import",
    icon: <Icon fontSize="small">backup</Icon>,
    route: "/import",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Crear Anuncio",
    key: "WaterAlert",
    icon: <Icon fontSize="small">local_drink</Icon>,
    route: "/WaterAlert",
    component: <WaterAlert />,
  },
  {
    type: "collapse",
    name: "Gestionar Usuarios",
    key: "Users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/Users",
    component: <Users />,
    hidden: false,
  },
  {
    type: "collapse",
    name: "Gestionar Comunidades",
    key: "Regions",
    icon: <Icon fontSize="small">pin_drop</Icon>,
    route: "/Comunities",
    component: <Regions />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    hidden: true,
  },
];

export default routes;
